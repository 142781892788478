<template>

  <!-- Phase 2 development will bring updates to this component with a new carousel. -->
  
  <div class="carousel-container">
    <v-carousel
      v-if="!!paginatedMedia.length"
      hide-delimiters
      height="400"
      :show-arrows="!!paginatedMedia.length"
      :continuous="false"
    >
      <v-carousel-item v-for="(page, index) in paginatedMedia" :key="index">
        <v-row no-gutters class="individual-carousel-item">
          <v-col
            cols="4"
            class="pb-0 mb-0 individual-media-wrapper"
            v-for="(mediaItem, itemIndex) in page"
            :key="itemIndex"
          >
            <div class="media-container">
              <v-img
                :aspect-ratio="16/4"
                class="media"
                :src="mediaItem.url"
              ></v-img>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <div v-else>
      <v-row no-gutters class="individual-carousel-item">
        <v-col
          cols="4"
          class="pb-0 mb-0 individual-media-wrapper"
          v-for="emptyMedia in itemsPerPage"
          :key="emptyMedia"
        >
          <div
            class="hb-text-light hb-font-body font-weight-regular empty-media-container"
          >
             No media available
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppDetailsMediaCarousel",
  props: {
    media: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      itemsPerPage: 3,
    };
  },
  computed: {
    paginatedMedia() {
      let pages = [];
      for (let media = 0; media < this.media.length; media += this.itemsPerPage) {
        pages.push(this.media.slice(media, media + this.itemsPerPage));
      }
      return pages;
    }
  },
};
</script>
<style lang="scss" scoped>
.individual-carousel-item {
  height: 100%;
  .individual-media-wrapper {
    padding-right: 35px;
    padding-top: 20px;
    &:last-child {
      padding-right: 0px;
    }
  }
  .media-container {
    padding-bottom: 20px;
    height: 100%;
  }
  .media {
    border-radius: 15px;
    height: 100%;
  }
  .empty-media-container {
    background-color: rgb(219, 219, 219);
    height: 250px;
    border-radius: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

<style lang="scss">
.carousel-container {
  .v-window__prev {
    margin-left: 90px;
  }
  .v-window__next {
    margin-right: 90px;
  }
  .v-window__prev,
  .v-window__next {
    width: 55px;
    height: 55px;
    background-color: rgb(255, 255, 255);
    .v-btn {
      width: 55px;
      height: 55px;
      color: rgb(0, 0, 0);
    }
  }
}
</style>
