<template>
  <v-sheet
    class="mx-auto application-launcher"
    height="100vh"
    width="400"
    color="#F9FAFB"
  >
    <hb-blade-header
      title="Application Launcher"
      class="blade-header"
      title-icon="mdi-apps"
      half
      backButtonOff
      @close="closeLauncher"
    >
    </hb-blade-header>
    <v-navigation-drawer
      v-model="drawer"
      @input="onInput"
      class="pt-2"
      color="#FFFFFF"
      width="400"
      style="height: calc(100vh - 67px)"
      dark
      flat
      clipped
      elevation-0
      mobile-breakpoint="415"
    >
      <v-list>
        <v-list-item-group>
          <v-list-item v-if="!platform_apps_loaded" class="d-block app-list">
            <div v-for="i in 2" :key="i">
              <v-skeleton-loader
                v-bind="skeltonLoaderAttrs"
                class="mb-2"
                type="list-item-avatar-two-line"
                light
              >
              </v-skeleton-loader>
            </div>
          </v-list-item>

          <v-list-item
            v-else
            two-line
            v-for="(item, index) in applications"
            :key="index"
            class="app-list mb-2"
          >
            <v-list-item-content class="pa-0">
              <v-list-item-title
                class="app-list-item"
                @click="openAppDialog(item, index)"
                :class="{ active: index === isAppActive }"
              >
                <div class="app-wrapper">
                  <v-row>
                    <v-col class="pr-0">
                      <span
                        class="app-icon"
                        :style="{
                          'background-image': 'url(' + item.icon + ')',
                        }"
                      ></span>
                    </v-col>

                    <v-col class="pl-0">
                      <span class="app-text pl-0">
                        <div class="app-title hb-font-header-3-medium">
                          {{ item.name }}
                        </div>
                        <div class="app-desc-wrapper" style="width: 242px">
                          <p class="app-description">{{ item.description }}</p>
                        </div>
                      </span>
                    </v-col>

                    <v-col>
                      <div class="platformApp-right-action">
                        <hb-btn icon activeStateOff color="#637381"
                          >mdi-chevron-right</hb-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <span
        v-if="!isAppsLoaded && platform_apps_loaded"
        class="ma-auto"
        style="font-size: 20px"
      >
        No Applications</span
      >

      <!-- <template v-slot:append >
      <div class="application-store-wrapper">
        <v-btn block class="application-store-btn">
          <v-row>
            <v-col>
              <span class="app-text pl-0">
                <div class="app-title hb-font-header-3-medium">Application Store</div>
                <div class="app-desc-wrapper">
                  <p class="app-description">View, search, and install applications</p>
                </div>
              </span>
            </v-col>

            <v-col>
              <div class="platformApp-right-action pl-8">
                <hb-btn icon color="#637381">mdi-chevron-right</hb-btn>
              </div>
            </v-col>
          </v-row>
        </v-btn>
      </div>
    </template> -->
      <template
        v-slot:append
        v-if="
          !!developerPortalAppId &&
          !!hasRolePermission('manage_app_subscriptions')
        "
      >
        <v-list class="subscription-section">
          <v-list-item-group>
            <v-list-item two-line class="app-list mb-2 mt-2">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  @click="openAppSubscriptionDIalog()"
                  class="app-list-item"
                >
                  <div class="app-wrapper">
                    <v-row class="mb-1">
                      <v-col class="pl-5 d-flex align-center">
                        <v-badge
                          color="#F26500"
                          :dot="!!getNectarAppsWithNotification.length"
                          :content="getNectarAppsWithNotification.length"
                          :value="getNectarAppsWithNotification.length"
                          overlap
                        >
                          <v-icon large color="#0EB5C5">
                            mdi-storefront
                          </v-icon>
                        </v-badge>
                      </v-col>

                      <v-col class="pl-0">
                        <span class="app-text pl-0">
                          <div class="app-title hb-font-header-3-medium">
                            Subscription Request
                          </div>
                          <div class="app-desc-wrapper" style="width: 242px">
                            <p class="app-description">
                              View, search, and install applications.
                            </p>
                          </div>
                        </span>
                      </v-col>

                      <v-col class="mr-3">
                        <div class="platformApp-right-action">
                          <hb-btn icon activeStateOff color="#637381"
                            >mdi-chevron-right</hb-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { EventBus } from "../../EventBus";
import { mapGetters } from "vuex";
export default {
  name: "AppLauncher",
  props: {
    launcherDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      skeltonLoaderAttrs: {
        class: "mb-1",
        elevation: 2,
      },
      appLoader: true,
      drawer: this.launcherDrawer,
      isAppsLoaded: false,
      showNotification: true,
    };
  },
  computed: {
    ...mapGetters({
      developerPortalAppId: "authenticationStore/getDeveloperPortalAppId",
      getNectarAppsWithNotification: "authenticationStore/getNectarAppsWithNotification",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    platform_apps_loaded() {
      return this.$store.getters["authenticationStore/getPlatformAppsLoaded"];
    },
    isAppActive() {
      return this.$store.getters["navigationStore/platformAppSelected"];
    },
    applications() {
      let applications =
        this.$store.getters["authenticationStore/getExtensionPoints"].app;
      this.isAppsLoaded = !!applications?.length;
      let map_editor_app = applications.find((app) =>
        app.name.toLowerCase().includes("map editor")
      );
      let is_map_edit_permit = this.hasRolePermission("prop_map_editor");
      if (map_editor_app && !is_map_edit_permit)
        applications = applications.filter(
          (app) => app.name !== map_editor_app.name
        );

      return applications;
    },
  },
  watch: {
    launcherDrawer(val) {
      this.drawer = val;
    }
  },

  methods: {
    closeLauncher() {
      this.$emit("closeAppLauncher");
    },
    onInput(isOpen) {
      this.$emit("drawerOpened", isOpen);
    },
    openAppDialog(data, index) {
      this.$store.dispatch("navigationStore/setAppLauncher", index);
      this.closeLauncher();
      EventBus.$emit("openAppDialog", data);
    },
    openAppSubscriptionDIalog() {
      this.closeLauncher();
      EventBus.$emit("openSubscriptionDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item--active {
  .app-list-item {
    z-index: 1;
  }
}
.active.app-list-item {
  background: #e1f4f4;
  z-index: 1;
}
.app-list:hover {
  .app-list-item {
    background: #f4f7f9;
    z-index: 1;
  }
}
::v-deep .blade-header {
  .v-card.v-card--flat.v-sheet.theme--light {
    background: #e0f5f5 !important;
  }
}
::v-deep .platform-app-icon .v-icon.notranslate.material-icons {
  color: white !important;
  font-size: 26px !important;
}
::v-deep .platformApp-right-action {
  .v-icon.notranslate.mdi.mdi-chevron-right {
    font-size: 34px !important;
  }
  .hb-v-btn-icon.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    top: 3px !important;
    left: 10px !important;
  }
}
::v-deep .application-store-btn.v-btn.v-btn--block.v-btn--contained {
  height: 65px !important;
  background: #f4f6f8 !important;
  border: 1px solid #dfe3e8 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
}
.application-store-wrapper {
  padding: 16px;
  border-top: 1px solid #dfe3e8;
  height: 88px;
  background: #ffffff;
}
.app-description {
  font-family: "Graphik Web";
  font-style: normal;
  font-size: 12px;
  font-weight: normal;
  line-height: 136%;
  color: #637381;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.app-title {
  color: #101318;
  font-family: "Graphik Web";
}
.app-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 40px;
  width: 216px;
}
.app-icon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 46px;
  height: 46px;
  border-radius: 4px;
  padding: 10px;
  background-size: contain;
}
.app-wrapper {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-list-item {
  border: 1px solid #dfe3e8;
  padding: 8px;
  border-radius: 4px;
  height: 65px;
  background: #ffffff;
}
.app-list {
  width: 400px;
}
.subscription-section {
  background-color: #f4f6f8 !important;
  border: 1px solid #dfe3e8;
}
</style>